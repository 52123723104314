// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.4.1 on 2021-08-23T19:36:34.106Z

const awsmobile = {
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_graphqlEndpoint: 'https://sgp5flxibvdidgtwckchqmnm4q.appsync-api.ca-central-1.amazonaws.com/graphql',
    aws_appsync_region: 'ca-central-1',
    aws_cognito_identity_pool_id: 'ca-central-1:8b0f4b9f-5ddd-47e2-a76f-d929f9560217',
    aws_cognito_region: 'ca-central-1',
    aws_project_region: 'ca-central-1',
    aws_user_files_s3_bucket: 'onechanhs-images',
    aws_user_files_s3_bucket_region: 'ca-central-1',
    aws_user_pools_id: 'ca-central-1_U3fIUN6P7',
    aws_user_pools_web_client_id: '3tlh84rvm1vm0hlfd4tstu32nk'
};

export default awsmobile;
